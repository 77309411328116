<template>
  <div class="dictionaryItemManagement">
    <div class="opera_btnArea">
      <el-button class="mainBtn" @click="dicEvent('add')" plain>+新增</el-button>
    </div>
    <el-table
      :data="tableData"
      stripe
      border
      style="width: 100%"
      max-height="700"
      align="center">
      <el-table-column
        label="序号"
        align="center"
        width="80">
        <template slot-scope="scope">
            {{ (form.current - 1) * form.size + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="类型"
        align="center">
      </el-table-column>
      <el-table-column
        prop="value"
        label="数据值"
        align="center">
      </el-table-column>
      <el-table-column
        prop="label"
        label="标签名"
        align="center">
      </el-table-column>
      <el-table-column
        prop="description"
        label="描述"
        align="center">
      </el-table-column>
      <el-table-column
        prop="sort"
        label="排序"
        align="center">
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="备注信息"
        align="center">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200"
        align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="dicEvent('edit', scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteEvent(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增/编辑 -->
    <dialogDiy :isShow="isShowDicEdit" :dialogDiyData="dialogDicDiyData"
    @dialogEvent="dialogDicEvent">
      <div class="edit_area" slot="dialogContent">
        <el-form :model="ruleEditForm" :rules="editRules" ref="ruleEditForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="类型" prop="type">
            <el-input v-model="ruleEditForm.type" placeholder="类型" disabled clearable></el-input>
          </el-form-item>
          <el-form-item label="数据值" prop="value">
            <el-input v-model="ruleEditForm.value" placeholder="数据值" clearable></el-input>
          </el-form-item>
          <el-form-item label="标签名" prop="label">
            <el-input v-model="ruleEditForm.label" placeholder="标签名" clearable></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input v-model="ruleEditForm.description" placeholder="描述" maxlength="250" clearable></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input-number v-model="ruleEditForm.sort" controls-position="right" :min="1" :max="100"></el-input-number>
          </el-form-item>
          <el-form-item label="备注信息" prop="remarks">
            <el-input v-model="ruleEditForm.remarks" placeholder="备注信息" maxlength="250" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialogDicEvent">取 消</el-button>
            <el-button type="primary" @click="enterEvent('ruleEditForm')">确 认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </dialogDiy>

    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="">
        <p>{{`是否确认删除数据类型为${rowData.label}的数据项?`}}</p>
        <div class="btn_area">
          <el-button @click="dialogDiyEvent">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import PublicDialog from '../PublicDialog'
import dialogDiy from '../commonModule/dialogDiy'
export default {
  name: 'dictionaryItemManagement',
  components: {
    PublicDialog,
    dialogDiy
  },
  data() {
    return {
      form: {
        current: 1,
        size: 50,
        dictId: ''
      },
      isShowDicEdit: false,
      dialogDicDiyData: {
        title: '新增',
        sizeStyle: {width: '450px',height: '540px'}
      },
      loading: false,
      tableData: [],
      ruleEditForm: {
        description: null,
        dictId: null,
        label: null,
        remarks: null,
        sort: 1,
        type: null,
        value: null,
        '$index': null,
        delFlag: null,
        createTime: null,
        updateTime: null,
        id: ''
      },
      editRules: {
        value: [
          { required: true, message: '请输入数据值', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '请输入标签名', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
      },
      types: '',
      rowData: {},
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log(newVal,oldVal)
        if(newVal.name == 'dictionaryItemManagement') {
          this.form.dictId = Number(newVal.query.dictId);
          this.ruleEditForm.dictId = Number(newVal.query.dictId);
          this.ruleEditForm.type = newVal.query.type;
          this.getDictionaryItem();
        }
      }
    }
  },
  methods: {
    // 获取字典项数据
    getDictionaryItem() {
      let obj = this.form;
      this.loading = true;
      this.$request.watchDictionItem(obj, res => {
        this.loading = false;
        if(res.code == 0) {
          this.tableData = res.data.records;
        }
      })
    },
    dialogDicEvent() {
      this.isShowDicEdit = !this.isShowDicEdit;
    },
    dicEvent(types, row = {}, index) {
      this.clearEvent();
      this.isShowDicEdit = true;
      this.types = types;
      this.rowData = row;
      if(types == 'edit') {
        this.dialogDicDiyData.title = '编辑';
        this.ruleEditForm['$index'] = index;
        this.ruleEditForm.createTime = row.createTime;
        this.ruleEditForm.updateTime = row.updateTime;
        this.ruleEditForm.delFlag = row.delFlag;
        this.ruleEditForm.description = row.description;
        this.ruleEditForm.label = row.label;
        this.ruleEditForm.sort = row.sort;
        this.ruleEditForm.remarks = row.remarks;
        this.ruleEditForm.value = row.value;
        this.ruleEditForm.id = row.id;
      }
    },
    clearEvent() {
      this.ruleEditForm['$index'] = null;
      this.ruleEditForm.createTime = null;
      this.ruleEditForm.updateTime = null;
      this.ruleEditForm.delFlag = null;
      this.ruleEditForm.description = null;
      this.ruleEditForm.label = null;
      this.ruleEditForm.sort = 1;
      this.ruleEditForm.remarks = null;
      this.ruleEditForm.value = null;
      this.ruleEditForm.id = null;
    },
    enterEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if(valid){
          if(this.types == 'add') {
            this.addDicEvent();
          } else {
            this.editDicEvent();
          }
        } else {
          return false;
        }
      })
    },
    addDicEvent() {
      this.$request.addItemObj(this.ruleEditForm, res => {
        if(res.code == 0) {
          this.Success('添加成功');
          this.getDictionaryItem();
          this.isShowDicEdit = false;
        }
      })
    },
    editDicEvent() {
      this.$request.putItemObj(this.ruleEditForm).then(res => {
        console.log(res,777)
        if(res.data.code == 0) {
          this.Success('编辑成功');
          this.getDictionaryItem();
          this.isShowDicEdit = false;
        }
      })
    },
    // 删除
    deleteEvent(row) {
      this.rowData = row;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent() {
      this.isShow = !this.isShow;
    },
    doEvent() {
      this.$request.delItemObj({id: this.rowData.id}).then(res => {
        if(res.data.code == 0) {
          this.Success('删除成功');
          this.isShow = false;
        }
      })
    },
  },
}
</script>

<style lang="less">
  .dictionaryItemManagement {
    box-sizing: border-box;
    padding: 30px 40px 40px;
    background-color: #fff;
    .opera_btnArea {
      margin-bottom: 20px;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: rgba(0, 0, 0, .1);
        border-radius: 3px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
        border-right: none;
    }
    .el-pagination {
      text-align: right;
    }
  }
</style>